import React from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import GroupCard from "components/elements/GroupCard/GroupCard";
import { GoArchive } from 'react-icons/go';
import SkeletonCourseCard from "components/elements/SkeletonCourseCard/SkeletonCourseCard";
import Button from "components/elements/Button/Button";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import { FaPlus } from 'react-icons/fa';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from 'react-modal';
import _ from 'lodash';
import "./InterestGroupsComponent.scss";

const InterestGroups = (props) => {
    const {
        groups,
        groupsFetching,
        submitGroup,
        submitGroupLoading,
        deleteGroup,
        setIsDeleteModalOpen,
        deleteGroupLoading,
        setIsManageGroupModalOpen,
        isManageGroupModalOpen,
        isDeleteModalOpen,
        selectedGroup,
        setSelectedGroup,
        checkCharacterCount,
        reactQuillRef
    } = props;

    return (
        <DashboardLayout>
            <div className="debate-groups-wrapper">
                <div className="section-title">
                    <h1>Grupos de interes</h1>
                    <Button className="secondary small" onClick={() => setIsManageGroupModalOpen(true)}>
                        <FaPlus />
                        <span>Añadir nuevo</span>
                    </Button>
                </div>

                {
                    groupsFetching ?
                        _.times(3, (i) => (
                            <SkeletonCourseCard key={i} />
                        )) :
                        groups.length ?
                            _.map(groups, (group, i) => (
                                <GroupCard
                                    key={i}
                                    picture={group.photo}
                                    title={group.name}
                                    active={group.active}
                                    description={group.description}
                                    messagesCount={group.messagesCount}
                                    editClickCallback={() => {
                                        setSelectedGroup(group);
                                        setIsManageGroupModalOpen(true);
                                    }}
                                    deleteClickCallback={() => {
                                        setSelectedGroup(group);
                                        setIsDeleteModalOpen(true);
                                    }}
                                />
                            )) :
                            <div>No hay grupos de interes registrados para mostrar.</div>
                }
            </div>

            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="delete-modal__wrapper">
                    <div className="modal-body">
                        <GoArchive />
                        <h3>¿Archivar "{selectedGroup?.name}"?</h3>
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => setIsDeleteModalOpen(false)}>Cancelar</Button>
                        <Button
                            className="danger"
                            loading={deleteGroupLoading}
                            onClick={() => deleteGroup(selectedGroup?.id)}>
                            Archivar
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isManageGroupModalOpen}
                onRequestClose={() => setIsManageGroupModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>{selectedGroup ? 'Editar' : 'Añadir'} Grupo de Interes</div>
                    </div>

                    <Formik
                        initialValues={{
                            name: selectedGroup?.name || '',
                            description: selectedGroup?.description || '',
                        }}
                        validationSchema={
                            yup.object().shape({
                                name: yup.string().required(),
                            })
                        }
                        onSubmit={(values) => submitGroup(values, selectedGroup?.id)}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">
                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="text"
                                            id="name"
                                            label="Título Grupo de Interes"
                                            placeholder="Escribe aquí el título del grupo de interes" />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="quill"
                                            id="description"
                                            label="Descripción"
                                            quillRef={reactQuillRef}
                                            onKeyDown={checkCharacterCount}
                                            multiline
                                            rows={5}
                                            placeholder="Descripción"
                                             />
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <Button className="light" onClick={() => setIsManageGroupModalOpen(false)}>Cancelar</Button>
                                    <Button onClick={formik.handleSubmit} loading={submitGroupLoading}>{selectedGroup ? 'Editar' : 'Añadir'}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </DashboardLayout>
    )
}

export default InterestGroups;
