import React from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import GroupCard from "components/elements/GroupCard/GroupCard";
import SkeletonCourseCard from "components/elements/SkeletonCourseCard/SkeletonCourseCard";
import { GoArchive } from 'react-icons/go';
import Button from "components/elements/Button/Button";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import Select from 'react-select'
import { FaPlus } from 'react-icons/fa';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from 'react-modal';
import moment from "moment";
import _ from 'lodash';
import "./DebateGroupsComponent.scss";

const DebateGroups = (props) => {
    const {
        groups,
        groupsFetching,
        submitGroup,
        submitGroupLoading,
        deleteGroup,
        setIsDeleteModalOpen,
        deleteGroupLoading,
        setIsManageGroupModalOpen,
        isManageGroupModalOpen,
        isDeleteModalOpen,
        selectedGroup,
        setSelectedGroup,
        usersSelected,
        setUsersSelected,
        usersList,
        checkCharacterCount,
        reactQuillRef
    } = props;

    const handleChange = (value, { action, removedValue }) =>{
        const users = value.map(u=>u.value);
        setUsersSelected(users);
    }

    return (
        <DashboardLayout>
            <div className="debate-groups-wrapper">
                <div className="section-title">
                    <h1>Grupos de debate</h1>
                    <Button className="secondary small" onClick={() => setIsManageGroupModalOpen(true)}>
                        <FaPlus />
                        <span>Añadir nuevo</span>
                    </Button>
                </div>

                {
                    groupsFetching ?
                        _.times(3, (i) => (
                            <SkeletonCourseCard key={i} />
                        )) :
                        groups.length ?
                            _.map(groups, (group, i) => (
                                <GroupCard
                                    key={i}
                                    picture={group.photo}
                                    title={group.name}
                                    date={moment(group.startDate).format('DD/MM') + ' - ' + moment(group.endDate).format('DD/MM')}
                                    active={group.active}
                                    description={group.description}
                                    messagesCount={group.messagesCount}
                                    editClickCallback={() => {
                                        setSelectedGroup(group);
                                        setUsersSelected(group.memberIds)
                                        setIsManageGroupModalOpen(true);
                                    }}
                                    deleteClickCallback={() => {
                                        setSelectedGroup(group);
                                        setIsDeleteModalOpen(true);
                                    }}
                                />
                            )) :
                            <div>No hay grupos de debates registrados para mostrar.</div>
                }
            </div>

            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="delete-modal__wrapper">
                    <div className="modal-body">
                        <GoArchive />
                        <h3>¿Archivar "{selectedGroup?.name}"?</h3>
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => setIsDeleteModalOpen(false)}>Cancelar</Button>
                        <Button
                            className="danger"
                            loading={deleteGroupLoading}
                            onClick={() => deleteGroup(selectedGroup?.id)}>
                            Archivar
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isManageGroupModalOpen}
                onRequestClose={() => setIsManageGroupModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>{selectedGroup ? 'Editar' : 'Añadir'} Grupo de Debate</div>
                    </div>

                    <Formik
                        initialValues={{
                            name: selectedGroup?.name || '',
                            description: selectedGroup?.description || '',
                            startDate: moment(selectedGroup?.startDate).format('DD/MM/YYYY'),
                            endDate: moment(selectedGroup?.endDate).format('DD/MM/YYYY'),
                        }}
                        validationSchema={
                            yup.object().shape({
                                name: yup.string().required(),
                                startDate: yup.date().required(),
                                endDate: yup.date().required(),
                            })
                        }
                        onSubmit={(values) => {
                            values.participants = usersSelected;
                            submitGroup(values, selectedGroup?.id);
                        }}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">
                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="text"
                                            id="name"
                                            label="Título Grupo de debate"
                                            placeholder="Escribe aquí el título del grupo de debate" />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="quill"
                                            id="description"
                                            label="Descripción"
                                            multiline
                                            onKeyDown={checkCharacterCount}
                                            quillRef={reactQuillRef}
                                            rows={5}
                                            placeholder="Descripción"
                                             />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <Select
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={usersList}
                                            onChange={handleChange}
                                            defaultValue={selectedGroup ? _.filter(usersList, function(o) { return selectedGroup.memberIds.includes(o.value); }) : null}
                                        />
                                    </div>


                                    <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="date"
                                            id="startDate"
                                            label="Fecha de inicio" />
                                    </div>

                                    <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="date"
                                            id="endDate"
                                            label="Fecha de finalización" />
                                    </div>

                                    <div><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>

                                </div>

                                <div className="modal-footer">
                                    <Button className="light" onClick={() => setIsManageGroupModalOpen(false)}>Cancelar</Button>
                                    <Button onClick={formik.handleSubmit} loading={submitGroupLoading}>{selectedGroup ? 'Editar' : 'Añadir'}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </DashboardLayout>
    )
}

export default DebateGroups;
