import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getNotificationsService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.notifications}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getNotificationsPaginationService = async (args) => {
    try {
        const {limit = 10, skip = 0} = args;
        let response = await customFetch(`${endpoints.notifications}?filter[limit]=${limit}&filter[skip]=${skip}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getNotificationsTotalService = async () => {
    try {
        let response = await customFetch(`${endpoints.notifications}/count`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createNotificationService = async ({title, body, screen, link}) => {
    try {
        let response = await customFetch(`${endpoints.notifications}`, {
            method: "POST",
            bodyReq: { title, body, screen, link},
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
