import React from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import { FaPaperPlane } from "react-icons/fa";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from "react-modal";
import moment from "moment";
import "./NotificationsComponent.scss";

const NotificationsComponent = (props) => {
    const { 
        notifications, 
        setIsManageModalNotificationOpen, 
        isManageNotificationModalOpen, 
        submitNotificationLoading, 
        createNotification,
        paginationTotal,
        paginationCurrent,
        paginationStep,
        paginationUrl,
    } = props;

    return (
        <DashboardLayout>
            <div className="notifications-wrapper">
                <div className="section-title">
                    <h1>Notificaciones</h1>
                    <Button className="secondary small" onClick={() => setIsManageModalNotificationOpen(true)}>
                        <FaPaperPlane />
                        <span>Enviar notificación</span>
                    </Button>
                </div>

                <div class="pagination-top">
                    <PaginationNavigator 
                        total={paginationTotal}
                        current={paginationCurrent}
                        step={paginationStep}
                        url={paginationUrl}
                    />
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>ID</th>
                            <th>Título</th>
                            <th>Mensaje</th>
                            <th>Enlace</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notifications.length ? (
                            notifications.map((not, i) => (
                                <tr key={i}>
                                    <td>{moment(not.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                    <td>{not.notificationId}</td>
                                    <td>{not.title}</td>
                                    <td>{not.bodyTruncated}</td>
                                    {
                                        not.link 
                                        ? 
                                        <td>
                                            <Button onClick={() => window.open(not.link)}>Ver</Button>
                                        </td> 
                                        : 
                                        <td>
                                            <i>N/D</i>
                                        </td>
                                    }
                                </tr>
                            ))
                        ) : (
                            <div className="empty">No hay notificaciones para mostrar.</div>
                        )}
                    </tbody>
                </table>

                <div class="pagination-bottom">
                    <PaginationNavigator 
                        total={paginationTotal}
                        current={paginationCurrent}
                        step={paginationStep}
                        url={paginationUrl}
                    />
                </div>

                <Modal
                    isOpen={isManageNotificationModalOpen}
                    onRequestClose={() => setIsManageModalNotificationOpen(false)}
                    className="sicomoro-modal"
                    overlayClassName="modal-overlay with-background"
                    ariaHideApp={false}
                >
                    <div className="manage-course-modal__wrapper">
                        <div className="modal-header">
                            <div>Enviar notificación</div>
                        </div>

                        <Formik
                            initialValues={{
                                title: "",
                                body: "",
                                link: ""
                            }}
                            validationSchema={yup.object().shape({
                                title: yup.string().required("El titulo es obligatorio"),
                                body: yup.string().required("La descripción es obligatoria")
                            })}
                            onSubmit={(values) => {
                                createNotification(values);
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <div className="modal-body">
                                        <div className="input-wrapper fill-row">
                                            <MaterialInput formik={formik} type="text" id="title" label="Título notificación" placeholder="Escribe aquí el título de la notificación" />
                                        </div>
                                        <div className="input-wrapper fill-row">
                                            <MaterialInput formik={formik} type="text" id="body" label="Descripción " placeholder="Escribe aquí la descripción de la notificación" />
                                        </div>
                                        <div className="input-wrapper fill-row">
                                            <MaterialInput formik={formik} type="text" id="link" label="Link" placeholder="Escribe aquí el link de la notificación" />
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <Button className="light" onClick={() => setIsManageModalNotificationOpen(false)}>
                                            Cancelar
                                        </Button>
                                        <Button onClick={formik.handleSubmit} loading={submitNotificationLoading}>
                                            Enviar
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal>
            </div>
        </DashboardLayout>
    );
};

export default NotificationsComponent;
