import React, { useEffect, useState } from "react";
import UsersComponent from '../components/UsersComponent';
import { deleteUserService, getUsersPaginationService, getUsersTotalService, createUserService, editUserService, getUsersService } from 'services/user';
import { geyPaymentsMethodUser } from 'services/payments';

const Users = (props) => {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    const [usersFetching, setUsersFetching] = useState(false);
    const [totalUsersFetching, setTotalUsersFetching] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [deleteUserLoading, setDeleteUserLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isManageUserModalOpen, setIsManageModalUserOpen] = useState(false);
    const [submitUserLoading, setSubmitUserLoading] = useState(false);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationCurrent, setPaginationCurrent] = useState(1);
    const [paginationStep, setPaginationStep] = useState(10);
    const [paginationUrl, setPaginationUrl] = useState('/usuarios/');
    const [searchbarInput, setSearchbarInput] = useState('');
    const [searchbarResults, setSearchbarResults] = useState([]);
    const [searchbarShowing, setSearchbarShowing] = useState(false);


    useEffect(() => {
        getUsers();
        getTotalUsers();
    }, []);

    useEffect(() => {
        getPaymentInfo(selectedUser);
    }, [isManageUserModalOpen]);

    useEffect(() => {
        console.log(selectedUser);
    }, [selectedUser]);

    const getUsers = async () => {
        setUsersFetching(true);

        try {
            //BEGIN Pagination

            const totalUsersData = await getUsersTotalService(); //CHANGE when copying

            let totalData = parseInt(totalUsersData.count);

            //No payments, function should exit to save up resources.

            if(totalData==0) {
                setUsersFetching(false); //CHANGE when copying
                return;
            }

            setPaginationTotal(totalData);

            //Check if pagenumber is ok, elsewhere it returns value to '1'

            let pageNumber = parseInt(props.location.pathname.split(paginationUrl)[1]);

            if(pageNumber > 1) {
                
                if(pageNumber*paginationStep-totalData >= paginationStep) pageNumber = 1;

            }

            if(pageNumber <= 0) pageNumber = 1;

            if(isNaN(pageNumber)) pageNumber = 1;

            setPaginationCurrent(pageNumber);

            let paginationOptions = {
                limit: paginationStep,
                skip: (pageNumber-1)*paginationStep,
            }


            //const paymentsRawData = await getUsersService();
            const data = await getUsersPaginationService(paginationOptions);

            setUsers(data);
            setSearchbarResults(data);
        } catch (err) {
            console.log(err);
        }

        setUsersFetching(false);
    }

    const getTotalUsers = async () => {
        setTotalUsersFetching(true);

        try {

            const data = await getUsersService();

            setTotalUsers(data);
        } catch (err) {
            console.log(err);
        }

        setTotalUsersFetching(false);
    }


    const getPaymentInfo = async (selectedUser) => {

        if(selectedUser.paymentInfo===undefined)

        try {

            let payment = await geyPaymentsMethodUser(selectedUser.profileId);

            if(payment.length>0) setSelectedUser({...selectedUser, paymentInfo: payment});

        } catch (err) {
            console.log(err);
        }

    }

    const submitUser = async (formValues, userId) => {
        setSubmitUserLoading(true);

        const userData = {
            ...formValues,
            profileId: userId
        }

        try {
            if (userId) { //edit an existing course

                const apiAllowedFields = ["name", "surname", "nif", "profileId"];

                for(var key of Object.keys(userData)) {
                    if(!apiAllowedFields.includes(key)) delete userData[key];
                }


                const editservice = await editUserService(userData, userId);
                await getUsers();
            }
            else {
                const data = await createUserService(userData);
                setUsers([...users, data]);
            }
            setIsManageModalUserOpen(false);
        } catch (err) {
            console.log(err)
        }
        finally {
            setSubmitUserLoading(false);
        }
    }

    const deleteUser = async (userId) => {
        setDeleteUserLoading(true);

        try {
            await deleteUserService(userId);
            await getUsers();
            setIsDeleteModalOpen(false);
        } catch (err) {
            console.log(err);
        }
        finally {
            setDeleteUserLoading(false);
        }
    }

    return <UsersComponent
        users={users}
        setUsers={setUsers}
        usersFetching={usersFetching}
        submitUser={submitUser}
        deleteUser={deleteUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        deleteUserLoading={deleteUserLoading}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isManageUserModalOpen={isManageUserModalOpen}
        setIsManageModalUserOpen={setIsManageModalUserOpen}
        submitUserLoading={submitUserLoading}
        paginationTotal={paginationTotal}
        paginationCurrent={paginationCurrent}
        paginationStep={paginationStep}
        paginationUrl={paginationUrl}
        searchbarResults={searchbarResults}
        setSearchbarResults={setSearchbarResults}
        searchbarInput={searchbarInput}
        setSearchbarInput={setSearchbarInput}
        setPaginationStep={setPaginationStep}
        totalUsers={totalUsers}
        searchbarShowing={searchbarShowing}
        setSearchbarShowing={setSearchbarShowing}
    />
}

export default Users;
